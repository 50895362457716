import { MEASUREMENT_DOWNSAMPLED_CHAR_UUID, MEASUREMENT_LIST_CHAR_UUID, MEASUREMENT_SRVC_UUID } from './api';
import { read, readList, write } from './utils';

const MAX_END = 2 ^ 32 - 1;

export const readMeasurementCount = async ({ device, serialNumber, start = 0, end = MAX_END }) => {
  if (!Number.isInteger(serialNumber)) {
    throw new Error(`serialNumber must be an integer`);
  }
  if (!Number.isInteger(start)) {
    throw new Error(`start must be an integer`);
  }
  if (!Number.isInteger(end)) {
    throw new Error(`end must be an integer`);
  }
  if (start < 0) {
    console.warn(`Clamping start to 0 from ${start}`);
    start = 0;
  }
  if (end > MAX_END) {
    console.warn(`clamping end to ${MAX_END} from ${end}`);
    end = MAX_END;
  }
  const req = new ArrayBuffer(12);
  const reqView = new DataView(req);
  reqView.setUint32(0, serialNumber, true);
  reqView.setUint32(4, start, true);
  reqView.setUint32(8, end, true);
  await write({ device, req });

  const res = await read({ device });
  const resView = new DataView(res);
  const count = resView.getUint32(0, true);
  console.log(`[${serialNumber}](${start} - ${end}): ${count}`);
  return count;
};

const createMeasurementListRequest = ({ serialNumber, start, end }) => {
  const req = new ArrayBuffer(4 + 4 + 4);
  const reqView = new DataView(req);

  let len = 4;
  reqView.setUint32(0, serialNumber, true);

  let hasStart = false;
  if (Number.isInteger(start)) {
    hasStart = true;
    len += 4;
    reqView.setUint32(4, start / 1000, true);
  }

  if (Number.isInteger(end)) {
    if (!hasStart) {
      throw new Error(`measurement list request has end but no start`);
    }
    len += 4;
    reqView.setUint32(8, end / 1000, true);
  }

  return req.slice(0, len);
};

export const readDeviceMeasurementList = async ({ device, serialNumber, onCount, onMeasurements, start = 0 }) => {
  let measCount = 0;
  await readList({
    device,
    serviceUuid: MEASUREMENT_SRVC_UUID,
    characteristicUuid: MEASUREMENT_LIST_CHAR_UUID,
    value: createMeasurementListRequest({ serialNumber, start }),
    onCount,
    onPayload: payload => {
      const payloadView = new DataView(payload);
      const measurements = [];
      let i = 0;
      while (i + 12 < payloadView.byteLength) {
        const timestamp = payloadView.getUint32(i + 0, true) * 1000;
        const temperature = payloadView.getFloat32(i + 4, true);
        const tscore = payloadView.getFloat32(i + 8, true);
        measurements.push({ timestamp, temperature, tscore });
        i += 12;
      }
      onMeasurements(measurements);
      measCount += measurements.length;
    },
    onStats: ({ diff, writeAvg, readAvg, processAvg }) => {
      const measAvg = measCount * 1000 / diff;
      console.log(`DeviceMeasurementList[${serialNumber}] write=${writeAvg.toFixed(2)}`);
      console.log(`read=${readAvg.toFixed(2)}ms/r meas=${measAvg.toFixed(2)}m/s process=${processAvg.toFixed(2)}ms/p`);
    },
  });
};

export const readDownsampledMeasurements = async ({ device, serialNumber, start, end, samples = 128 }) => {
  const buckets = []
  await readList({
    device,
    serviceUuid: MEASUREMENT_SRVC_UUID,
    characteristicUuid: MEASUREMENT_DOWNSAMPLED_CHAR_UUID,
    value: createReadDownSampledMeasurementsRequest({ serialNumber, start, end, samples }),
    onPayload: payload => {
      const payloadView = new DataView(payload);
      let i = 0;
      while (i + 12 < payloadView.byteLength) {
        const timestamp = payloadView.getUint32(i + 0, true) * 1000;
        const count = payloadView.getUint32(i + 4, true);
        let temperature = null;
        if (count > 0) {
          temperature = payloadView.getFloat32(i + 8, true);
        }
        buckets.push({ timestamp, count, temperature });
        i += 12;
      }
    },
    onStats: stats => console.log(stats),
  });
  return buckets;
};

const createReadDownSampledMeasurementsRequest = ({ serialNumber, start, end, samples }) => {
  const req = new ArrayBuffer(4 + 4 + 4 + 4);
  const reqView = new DataView(req);
  reqView.setUint32(0, serialNumber, true);
  reqView.setUint32(4, Math.round(start / 1000), true);
  reqView.setUint32(8, Math.round(end / 1000), true);
  reqView.setUint32(12, samples, true);
  return req;
};
