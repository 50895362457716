import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { findBaseDevice, readBaseStationInformation, readBaseStationSettingList } from '../lib/ble/base-station';
import { readDeviceMeasurementList } from '../lib/ble/measurement';
import {
  addAvailabilityChangedListener,
  addDisconnectedListener,
  connect,
  disconnect,
  getAvailability,
} from '../lib/ble/utils';
import {
  readDeviceMeasurementCount,
  readDeviceMeasurementTimestampMaximum,
  writeDeviceMeasurementList,
} from '../lib/storage/measurements';
import { isNonEmptyString } from '../lib/utils';
import {
  readDeviceListFromBase,
} from "./data-sources";
import { readDeviceSettingListFromBase } from './device-settings';
import { handlePending, handleError, handleFulfilled, selectError, selectLoading } from './util';

const bluetoothDevices = new Map();

const deviceAdapter = createEntityAdapter({
  selectId: device => device.serialNumber,
});

const initialState = {
  loading: 0,
  error: null,
  availability: false,
  baseDeviceId: null,
  connecting: false,

  deviceListSyncTimeout: null,
  settingsListSyncTimeout: null,
  measurementsListSyncTimeout: null,
};

export const init = createAsyncThunk(
  'ble/init',
  async (request, api) => {
    const onAvailabilityChanged = availability => api.dispatch(setAvailability(availability));
    const ble = navigator.bluetooth;
    if (ble) {
      const availability = await getAvailability({ ble });
      onAvailabilityChanged(availability);
      addAvailabilityChangedListener({ ble, onAvailabilityChanged });
    } else {
      onAvailabilityChanged(false);
    }
  },
);

const connectToBase = createAsyncThunk(
  'ble/connectToBase',
  async (request, api) => {
    const { device } = request;

    const onDisconnected = () => api.dispatch(handleDisonnected());
    addDisconnectedListener({ device, onDisconnected });

    await connect({ device });
  },
);

const sync = createAsyncThunk(
  'ble/sync',
  async (request, api) => {
    const { user } = request;
    const device = selectBaseDevice(api.getState());

    await readBaseStationInformation({ device });
    await readBaseStationSettingList({ device });

    await api.dispatch(deviceListSync({ user }));
    await api.dispatch(settingsListSync({ user }));
    await api.dispatch(measurementsListSync({ user }));
  },
);

const deviceListSync = createAsyncThunk(
  'ble/deviceListSync',
  async (request, api) => {
    const { user } = request;
    const device = selectBaseDevice(api.getState());

    if (!device || !device.gatt.connected) {
      return;
    }

    let { payload: devices } = await api.dispatch(readDeviceListFromBase({ user, device }));
    devices = devices.map(device => ({ ...device }));
    for (const device of devices) {
      const { count } = await readDeviceMeasurementCount({
        user,
        serialNumber: device.serialNumber,
      });
      device.measurementStorageCount = count;
    }
    const deviceListSyncTimeout = setTimeout(
      () => api.dispatch(deviceListSync(request)),
      10 * 1000
    );
    api.dispatch(setDeviceListSyncTimeout(deviceListSyncTimeout));
    return devices;
  },
);

const settingsListSync = createAsyncThunk(
  'ble/settingsSync',
  async (request, api) => {
    const device = selectBaseDevice(api.getState());

    if (!device || !device.gatt.connected) {
      return;
    }

    await api.dispatch(readDeviceSettingListsFromBase(request));
    const settingsListSyncTimeout = setTimeout(
      () => api.dispatch(settingsListSync(request)),
      10 * 1000
    );
    api.dispatch(setSettingsListSyncTimeout(settingsListSyncTimeout));
  },
);

const readDeviceSettingListsFromBase = createAsyncThunk(
  'ble/readDeviceSettingListsFromBase',
  async (request, api) => {
    const { user } = request;
    const device = selectBaseDevice(api.getState());

    if (!device || !device.gatt.connected) {
      return;
    }

    const serialNumbers = selectDeviceIds(api.getState());
    for (const serialNumber of serialNumbers) {
      await api.dispatch(readDeviceSettingListFromBase({ user, device, serialNumber }));
    }
  },
);

const measurementsListSync = createAsyncThunk(
  'ble/measurementsListSync',
  async (request, api) => {
    const device = selectBaseDevice(api.getState());

    if (!device || !device.gatt.connected) {
      return;
    }

    await api.dispatch(readDeviceMeasurementListsFromBase(request));
    const measurementsListSyncTimeout = setTimeout(
      () => api.dispatch(measurementsListSync(request)),
      10 * 1000
    );
    api.dispatch(setMeasurementsListSyncTimeout(measurementsListSyncTimeout));
  },
);

const readDeviceMeasurementListsFromBase = createAsyncThunk(
  'ble/readDeviceMeasurementListsFromBase',
  async (request, api) => {
    const { user } = request;
    const serialNumbers = selectDeviceIds(api.getState());
    for (const serialNumber of serialNumbers) {
      await api.dispatch(readDeviceMeasurementListFromBase({ user, serialNumber }));
    }
  },
);

const readDeviceMeasurementListFromBase = createAsyncThunk(
  'ble/readDeviceMeasurementListFromBase',
  async (request, api) => {
    const { user, serialNumber } = request;
    const device = selectBaseDevice(api.getState());

    if (!device || !device.gatt.connected) {
      return;
    }

    const start = await readDeviceMeasurementTimestampMaximum({ user, serialNumber });

    const onCount = count => api.dispatch(upsertDeviceMeasurementSyncLeftCount({ serialNumber, count }));

    let measurements = [];
    const writePromises = [];
    const onMeasurements = newMeasurements => {
      measurements = measurements.concat(newMeasurements);
      if (measurements.length >= 1000) {
        writePromises.push(api.dispatch(writeDeviceMeasurementListToStorage({ user, serialNumber, measurements })));
        measurements = [];
      }
      api.dispatch(incrementDeviceMeasurementSyncLeftCount({ serialNumber, count: -newMeasurements.length }))
    };

    await readDeviceMeasurementList({ device, serialNumber, onCount, onMeasurements, start: start + 1 });

    if (measurements.length > 0) {
      writePromises.push(api.dispatch(writeDeviceMeasurementListToStorage({ user, serialNumber, measurements })));
    }
    await Promise.all(writePromises);
  },
);

const writeDeviceMeasurementListToStorage = createAsyncThunk(
  'ble/writeDeviceMeasurementListToStorage',
  async (request, api) => {
    const { user, serialNumber, measurements } = request;
    await writeDeviceMeasurementList({ user, serialNumber, measurements });
  },
);

export const findAndConnectToBase = createAsyncThunk(
  'ble/findAndConnectToBase',
  async (request, api) => {
    const { user } = request;

    const device = await findBaseDevice();
    bluetoothDevices.set(device.id, device);
    api.dispatch(setBaseDeviceId(device.id));

    await api.dispatch(connectToBase({ device }));

    await api.dispatch(sync({ user, device }));
  },
);

export const disconnectFromBase = createAsyncThunk(
  'ble/disconnectFromBase',
  async (request, api) => {
    const { baseDevice: device } = selectBle(api.getState());
    await disconnect({ device });
  },
);

export const bleSlice = createSlice({
  name: 'ble',
  initialState: deviceAdapter.getInitialState(initialState),
  reducers: {
    handleDisonnected: (state, action) => {
      clearTimeout(state.deviceListSyncTimeout);
      state.deviceListSyncTimeout = null;
      clearTimeout(state.settingsListSyncTimeout);
      state.settingsListSyncTimeout = null;
      clearTimeout(state.measurementsListSyncTimeout);
      state.measurementsListSyncTimeout = null;

      state.syncing = false;
      state.connecting = false;
      state.baseDeviceId = null;

      state.loading = 0;
      state.error = null;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setBaseDeviceId: (state, action) => {
      state.baseDeviceId = action.payload;
    },
    setDeviceListSyncTimeout: (state, action) => {
      clearTimeout(state.deviceListSyncTimeout);
      state.deviceListSyncTimeout = action.payload;
    },
    setSettingsListSyncTimeout: (state, action) => {
      clearTimeout(state.settingsListSyncTimeout);
      state.settingsListSyncTimeout = action.payload;
    },
    setMeasurementsListSyncTimeout: (state, action) => {
      clearTimeout(state.measurementsListSyncTimeout);
      state.measurementsListSyncTimeout = action.payload;
    },
    upsertDeviceMeasurementSyncLeftCount: (state, action) => {
      const { serialNumber, count } = action.payload;
      deviceAdapter.upsertOne(state, { serialNumber, measurementSyncLeftCount: count });
    },
    incrementDeviceMeasurementSyncLeftCount: (state, action) => {
      const { serialNumber, count } = action.payload;
      const device = selectById({ ble: state }, serialNumber);
      const measurementSyncLeftCount = device.measurementSyncLeftCount + count;
      deviceAdapter.upsertOne(state, { serialNumber, measurementSyncLeftCount });
    },
  },
  extraReducers: {
    [connectToBase.pending]: handlePending(state => {
      state.connecting = true;
    }),
    [connectToBase.fulfilled]: handleFulfilled(state => {
      state.connecting = false;
    }),
    [connectToBase.rejected]: handleError(state => {
      state.connecting = false;
    }),

    [sync.pending]: handlePending((state) => {
      state.syncing = true;
    }),
    [sync.fulfilled]: handleFulfilled((state) => {
      state.syncing = false;
    }),
    [sync.rejected]: handleError((state) => {
      state.syncing = false;
    }),

    [deviceListSync.pending]: handlePending(),
    [deviceListSync.fulfilled]: handleFulfilled((state, action) => {
      const devices = action.payload;
      deviceAdapter.upsertMany(state, devices);
    }),
    [deviceListSync.rejected]: handleError(),

    [settingsListSync.pending]: handlePending(),
    [settingsListSync.fulfilled]: handleFulfilled(),
    [settingsListSync.rejected]: handleError(),

    [readDeviceSettingListsFromBase.pending]: handlePending(),
    [readDeviceSettingListsFromBase.fulfilled]: handleFulfilled(),
    [readDeviceSettingListsFromBase.rejected]: handleError(),

    [measurementsListSync.pending]: handlePending(),
    [measurementsListSync.fulfilled]: handleFulfilled(),
    [measurementsListSync.rejected]: handleError(),

    [readDeviceMeasurementListsFromBase.pending]: handlePending(),
    [readDeviceMeasurementListsFromBase.fulfilled]: handleFulfilled(),
    [readDeviceMeasurementListsFromBase.rejected]: handleError(),

    [readDeviceMeasurementListFromBase.pending]: handlePending((state, action) => {
      const { serialNumber } = action.meta.arg;
      deviceAdapter.upsertOne(state, { serialNumber, measurementSync: true });
    }),
    [readDeviceMeasurementListFromBase.fulfilled]: handleFulfilled((state, action) => {
      const { serialNumber } = action.meta.arg;
      deviceAdapter.upsertOne(state, { serialNumber, measurementSync: false });
    }),
    [readDeviceMeasurementListFromBase.rejected]: handleError((state, action) => {
      const { serialNumber } = action.meta.arg;
      deviceAdapter.upsertOne(state, { serialNumber, measurementSync: false });
    }),

    [writeDeviceMeasurementListToStorage.pending]: handlePending(),
    [writeDeviceMeasurementListToStorage.fulfilled]: handleFulfilled((state, action) => {
      const { serialNumber, measurements } = action.meta.arg;
      const device = selectById({ ble: state }, serialNumber);
      const measurementStorageCount = device.measurementStorageCount + measurements.length;
      deviceAdapter.upsertOne(state, { serialNumber, measurementStorageCount });
    }),
    [writeDeviceMeasurementListToStorage.rejected]: handleError(),
  },
});

export const selectBle = state => {
  let baseDevice = null
  const baseDeviceId = state.ble.baseDeviceId;
  if (isNonEmptyString(baseDeviceId) && bluetoothDevices.has(baseDeviceId)) {
    baseDevice = bluetoothDevices.get(baseDeviceId);
  }
  return {
    ...state.ble,
    baseDevice,
  };
};

const { selectIds, selectById } = deviceAdapter.getSelectors(selectBle);

export const selectBaseDevice = state => selectBle(state).baseDevice;

export const selectBleLoading = selectLoading(selectBle);
export const selectBleError = selectError(selectBle);

export const selectBleConnectionStatus = state => {
  const { connecting } = selectBle(state);
  if (connecting) {
    return 'Connecting';
  }
  const baseDevice = selectBaseDevice(state);
  if (!baseDevice || !baseDevice.gatt.connected) {
    return 'Disconnected';
  }
  return 'Connected';
};

export const selectBleConnected = state => {
  const status = selectBleConnectionStatus(state);
  return status === 'Connected';
};

export const selectDeviceIds = state => selectIds(state);
export const selectDevice = serialNumber => state => selectById(state, serialNumber);

export const selectDeviceMeasurementSync = serialNumber => state => {
  return selectById(state, serialNumber).measurementSync;
}

export const selectDeviceMeasurementStorageProgress = serialNumber => state => {
  let { count, measurementStorageCount } = selectDevice(serialNumber)(state);
  count = Number.parseInt(count);
  if (Number.isNaN(count) || count < 0) {
    count = 1;
  }
  measurementStorageCount = Number.parseInt(measurementStorageCount);
  if (Number.isNaN(measurementStorageCount) || measurementStorageCount < 0) {
    measurementStorageCount = count;
  }
  const progress = measurementStorageCount * 100 / count;
  return progress;
};

export const selectDeviceMeasurementSyncProgress = serialNumber => state => {
  let { count, measurementSyncLeftCount } = selectDevice(serialNumber)(state);
  count = Number.parseInt(count);
  if (Number.isNaN(count) || count < 0) {
    count = 1;
  }
  measurementSyncLeftCount = Number.parseInt(measurementSyncLeftCount);
  if (Number.isNaN(measurementSyncLeftCount) || measurementSyncLeftCount < 0) {
    measurementSyncLeftCount = count;
  }
  const progress = (count - measurementSyncLeftCount) * 100 / count;
  return progress;
};

const {
  handleDisonnected,
  incrementDeviceMeasurementSyncLeftCount,
  setAvailability,
  setBaseDeviceId,
  setDeviceListSyncTimeout,
  setMeasurementsListSyncTimeout,
  setSettingsListSyncTimeout,
  upsertDeviceMeasurementSyncLeftCount,
} = bleSlice.actions

export default bleSlice.reducer;
