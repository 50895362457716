import { bytesToHex } from '../utils';
import { SETTING_LIST_CHAR_UUID, SETTING_SRVC_UUID, SETTING_UPDATE_CHAR_UUID } from './api';
import { addValueChangedListener, buildOnListValueChanged, startNotifications, stopNotifications, write } from './utils';

const createSettingListRequest = serialNumber => {
  const req = new ArrayBuffer(4);
  const reqView = new DataView(req);
  reqView.setUint32(0, serialNumber, true);
  return req;
};

const listenForDeviceSettingList = async ({ device }) => new Promise(async (resolve, reject) => {
  try {
    const settings = {};
    const { cancel } = await addValueChangedListener({
      device,
      serviceUuid: SETTING_SRVC_UUID,
      characteristicUuid: SETTING_LIST_CHAR_UUID,
      onValueChanged: buildOnListValueChanged({
        onError: errorType => {
          cancel();
          reject(new Error(`Failed to read setting list: ${errorType}`));
        },
        onFinish: () => {
          cancel();
          resolve(settings);
        },
        onCount: _ => { },
        onValue: data => {
          const dataView = new DataView(data);
          const rid = dataView.getUint8(0);
          const status = dataView.getUint8(1);
          const raw_value_len = dataView.getUint8(2);
          const raw_value = dataView.buffer.slice(3, 3 + raw_value_len);
          const value = bytesToHex(raw_value);
          const raw_update_len = dataView.getUint8(3 + raw_value_len);
          const raw_update = dataView.buffer.slice(3 + raw_value_len + 1, 3 + raw_value_len + 1 + raw_update_len);
          const update = bytesToHex(raw_update);
          settings[rid] = { rid, status, value, update };
        },
      }),
    });
  } catch (err) {
    reject(err);
  }
});

export const readDeviceSettingList = async ({ device, serialNumber }) => {
  // const start = Date.now();

  const settingListPromise = listenForDeviceSettingList({ device });
  await write({
    device,
    serviceUuid: SETTING_SRVC_UUID,
    characteristicUuid: SETTING_LIST_CHAR_UUID,
    value: createSettingListRequest(serialNumber),
  });
  await startNotifications({
    device,
    serviceUuid: SETTING_SRVC_UUID,
    characteristicUuid: SETTING_LIST_CHAR_UUID,
  });
  const settings = await settingListPromise;
  await stopNotifications({
    device,
    serviceUuid: SETTING_SRVC_UUID,
    characteristicUuid: SETTING_LIST_CHAR_UUID,
  });

  // const diff = Date.now() - start;
  // console.log(`SettingList[${serialNumber}]: Took ${diff.toFixed(2)}ms`);

  return settings;
};

const createSettingUpdateRequest = ({ serialNumber, rid, update = '' }) => {
  const updateLen = update.length / 2;
  const req = new ArrayBuffer(4 + 1 + 1 + updateLen);
  const reqView = new DataView(req);
  reqView.setUint32(0, serialNumber, true);
  reqView.setUint8(4, rid);
  reqView.setUint8(5, updateLen);
  for (let i = 0; i < updateLen; i++) {
    reqView.setUint8(6 + i, Number.parseInt(update.slice(i * 2, i * 2 + 2), 16));
  }
  return req;
};

export const updateDeviceSetting = async ({ device, serialNumber, rid, update }) => {
  const start = Date.now();

  await write({
    device,
    serviceUuid: SETTING_SRVC_UUID,
    characteristicUuid: SETTING_UPDATE_CHAR_UUID,
    value: createSettingUpdateRequest({ serialNumber, rid, update }),
  });

  const diff = Date.now() - start;
  console.log(`SettingUpdate[${serialNumber}]: Took ${diff.toFixed(2)}ms ${rid} - ${update}`);
};
