import {
  BASE_STATION_SRVC_UUID,
  BASE_STATION_INFORMATION_CHAR_UUID,
  BASE_STATION_SETTING_LIST_CHAR_UUID,
  BASE_STATION_SETTING_UPDATE_CHAR_UUID,
  DEVICE_SRVC_UUID,
  MEASUREMENT_SRVC_UUID,
  SETTING_SRVC_UUID,
  WIFI_CONFIG_SRVC_UUID,
  WIFI_SCANNER_SRVC_UUID,
} from './api';
import { read, requestDevice, write } from './utils';

export const findBaseDevice = async () => {
  const device = await requestDevice({
    ble: navigator.bluetooth,
    filters: [{ services: [BASE_STATION_SRVC_UUID] }],
    optionalServices: [
      BASE_STATION_SRVC_UUID,
      DEVICE_SRVC_UUID,
      SETTING_SRVC_UUID,
      MEASUREMENT_SRVC_UUID,
      WIFI_CONFIG_SRVC_UUID,
      WIFI_SCANNER_SRVC_UUID,
    ],
  });
  return device;
};

export const readBaseStationInformation = async ({ device }) => {
  await read({
    device,
    serviceUuid: BASE_STATION_SRVC_UUID,
    characteristicUuid: BASE_STATION_INFORMATION_CHAR_UUID,
  });
  return {};
};

export const readBaseStationSettingList = async ({ device }) => {
  const value = new ArrayBuffer(1);
  const view = new DataView(value);
  view.setUint8(0, 0x01);
  await write({
    device,
    serviceUuid: BASE_STATION_SRVC_UUID,
    characteristicUuid: BASE_STATION_SETTING_LIST_CHAR_UUID,
    value,
  });
  await read({
    device,
    serviceUuid: BASE_STATION_SRVC_UUID,
    characteristicUuid: BASE_STATION_SETTING_LIST_CHAR_UUID,
  });
  return {};
};

export const updateBaseStationSetting = async ({ device }) => {
  const value = new ArrayBuffer(1);
  const view = new DataView(value);
  view.setUint8(0, 0x01);
  await write({
    device,
    serviceUuid: BASE_STATION_SRVC_UUID,
    characteristicUuid: BASE_STATION_SETTING_UPDATE_CHAR_UUID,
    value,
  });
};
