import { v4 } from 'uuid';

export const buildQueue = ({ log = _ => { }, tag = v4() } = {}) => {
  let queue = Promise.resolve();

  let count = 0;
  let numQueued = 0;
  let maxNumQueued = 0;

  const wrap = fn => (...params) => {
    const id = count++;
    numQueued++;
    if (numQueued > maxNumQueued) {
      maxNumQueued = numQueued;
    }

    log(`[${tag}]: Queued ${id}`);
    const callPromise = queue
      .then(() => {
        log(`[${tag}]: Called ${id}`);
        return fn(...params);
      })
      .finally(() => numQueued--);

    queue = callPromise
      .then(() => log(`[${tag}]: Resolved ${id}`))
      .catch(() => log(`[${tag}]: Failed ${id}`))

    return callPromise;
  };

  const reset = () => {
    log(`[${tag}] Reset queue`);
    queue = Promise.resolve();
  };

  return { wrap, reset };
};