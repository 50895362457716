import { DEVICE_LIST_CHAR_UUID, DEVICE_SRVC_UUID } from './api';
import { addValueChangedListener, buildOnListValueChanged, startNotifications, stopNotifications } from './utils';

const listenForDeviceList = async ({ device }) => new Promise(async (resolve, reject) => {
  try {
    const devices = [];
    const { cancel } = await addValueChangedListener({
      device,
      serviceUuid: DEVICE_SRVC_UUID,
      characteristicUuid: DEVICE_LIST_CHAR_UUID,
      onValueChanged: buildOnListValueChanged({
        onError: errorType => {
          cancel();
          reject(new Error(`Failed to read device list: ${errorType}`));
        },
        onFinish: () => {
          cancel();
          resolve(devices);
        },
        onCount: _ => { },
        onValue: data => {
          const dataView = new DataView(data);
          const serialNumber = dataView.getUint32(0, true);
          const min = dataView.getUint32(4, true) * 1000;
          const max = dataView.getUint32(8, true) * 1000;
          const count = dataView.getUint32(12, true);
          devices.push({ serialNumber, min, max, count });
        },
      }),
    });
  } catch (err) {
    reject(err);
  }
});

export const readDeviceList = async ({ device }) => {
  // const start = Date.now();

  const deviceListPromise = listenForDeviceList({ device });
  await startNotifications({
    device,
    serviceUuid: DEVICE_SRVC_UUID,
    characteristicUuid: DEVICE_LIST_CHAR_UUID,
  });
  const devices = await deviceListPromise;
  await stopNotifications({
    device,
    serviceUuid: DEVICE_SRVC_UUID,
    characteristicUuid: DEVICE_LIST_CHAR_UUID,
  });

  // const diff = Date.now() - start;
  // console.log(`DeviceList: Took ${diff.toFixed(2)}ms`);

  return devices;
};
