import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import workerPouch from 'worker-pouch';
PouchDB.plugin(PouchDBFind);

const users = new Map();

export async function createUserDb({ user, options }) {
  const { name, indexes = [], hook = () => { } } = options;

  let adapter;
  const isWorkerSupported = await workerPouch.isSupportedBrowser();
  if (isWorkerSupported) {
    PouchDB.adapter('worker', workerPouch);
    adapter = 'worker';
  }

  const db = new PouchDB(`${user.uid}_${name}`, {
    auto_compaction: true,
    revs_limit: 25,
    adapter,
  });
  await Promise.all(indexes.map(index => db.createIndex({ index })));
  await hook(db);
  return db;
}

export async function getUserDb({ user, options }) {
  const { uid } = user;
  if (!users.has(uid)) {
    users.set(uid, { dbs: new Map() });
  }
  const { dbs } = users.get(uid);
  const { name } = options;
  if (!dbs.has(name)) {
    dbs.set(name, createUserDb({ user, options }));
  }
  return await dbs.get(name);
}
