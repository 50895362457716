import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { readDeviceSettingList, updateDeviceSetting } from "../lib/ble/setting";
import { listDeviceSettings, upsertDeviceSettings } from "../lib/storage/device-settings";
import { isNonNullObject, numSettingParseFn } from "../lib/utils";
import { handleError, handleFulfilled, handlePending } from "./util";

const makeDeviceSettingId = (serialNumber, rid) => `${serialNumber}_${rid}`;

const deviceSettingsAdapter = createEntityAdapter({
  selectId: setting => makeDeviceSettingId(setting.serialNumber, setting.rid),
});

export const readDeviceSettingListFromStorage = createAsyncThunk(
  "deviceSettings/readDeviceSettingListFromStorage",
  async (request, api) => {
    const { user, serialNumber } = request;
    const deviceSettings = await listDeviceSettings({ user, serialNumber });
    return deviceSettings;
  },
);

export const readDeviceSettingListFromBase = createAsyncThunk(
  "deviceSettings/readDeviceSettingListFromBase",
  async (request, api) => {
    const { user, device, serialNumber } = request;
    const res = await readDeviceSettingList({ device, serialNumber });
    const deviceSettings = Object.keys(res).map(key => ({ ...res[key], serialNumber }));
    await api.dispatch(writeDeviceSettingsToStorage({ user, deviceSettings }));
    return deviceSettings;
  },
);

export const updateDeviceSettingToBase = createAsyncThunk(
  "deviceSettings/updateDeviceSettingToBase",
  async (request, api) => {
    const { device, serialNumber, rid, update } = request;
    await updateDeviceSetting({ device, serialNumber, rid, update });
  },
);

const writeDeviceSettingsToStorage = createAsyncThunk(
  "deviceSettings/writeDeviceSettingsToStorage",
  async (request, api) => {
    const { user, deviceSettings } = request;
    return await upsertDeviceSettings({ user, deviceSettings });
  },
);

const deviceSettingsSlice = createSlice({
  name: "deviceSettings",
  initialState: deviceSettingsAdapter.getInitialState({
    loading: 0,
    error: null,
  }),
  extraReducers: {
    [readDeviceSettingListFromStorage.pending]: handlePending(),
    [readDeviceSettingListFromStorage.fulfilled]: handleFulfilled((state, action) => {
      const deviceSettings = action.payload;
      deviceSettingsAdapter.upsertMany(state, deviceSettings);
    }),
    [readDeviceSettingListFromStorage.rejected]: handleError(),

    [readDeviceSettingListFromBase.pending]: handlePending(),
    [readDeviceSettingListFromBase.fulfilled]: handleFulfilled((state, action) => {
      const deviceSettings = action.payload;
      deviceSettingsAdapter.upsertMany(state, deviceSettings);
    }),
    [readDeviceSettingListFromBase.rejected]: handleError(),

    [updateDeviceSettingToBase.pending]: handlePending(),
    [updateDeviceSettingToBase.fulfilled]: handleFulfilled(),
    [updateDeviceSettingToBase.rejected]: handleError(),

    [writeDeviceSettingsToStorage.pending]: handlePending(),
    [writeDeviceSettingsToStorage.fulfilled]: handleFulfilled((state, action) => {
      const { deviceSettings } = action.meta.arg;
      deviceSettingsAdapter.upsertMany(state, deviceSettings);
    }),
    [writeDeviceSettingsToStorage.rejected]: handleError(),
  },
});

const selectDeviceSettingsState = state => state.deviceSettings;

const {
  selectAll,
} = deviceSettingsAdapter.getSelectors(selectDeviceSettingsState);

export const selectDeviceSettings = serialNumber => state =>
  selectAll(state).filter(setting => setting.serialNumber === serialNumber);

export const selectDeviceSetting = (serialNumber, rid) => state => {
  const deviceSettings = selectDeviceSettings(serialNumber)(state);
  const deviceSetting = deviceSettings.find(deviceSetting => deviceSetting.rid === rid);
  if (isNonNullObject(deviceSetting)) {
    return deviceSetting;
  }
  return null;
};

const selectDeviceSettingValueUpdateNumber = ({ serialNumber, rid }) => state => {
  const setting = selectDeviceSetting(serialNumber, rid)(state);
  if (!setting) {
    return { value: -1, update: -1 };
  }
  let { value, update } = setting;
  value = numSettingParseFn(value);
  update = numSettingParseFn(update);
  return { value, update };
};

export const selectMeasurementIntervalSeconds = serialNumber =>
  selectDeviceSettingValueUpdateNumber({ serialNumber, rid: 20 });

export const selectSysBatteryLevel = serialNumber =>
  selectDeviceSettingValueUpdateNumber({ serialNumber, rid: 24 });

export default deviceSettingsSlice.reducer;
