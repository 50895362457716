import { buildQueue } from '../queue';

const { wrap, reset } = buildQueue();

export async function getAvailability({ ble }) {
  const availability = await ble.getAvailability();
  return availability;
}

export function addAvailabilityChangedListener({ ble, onAvailabilityChanged }) {
  ble.addEventListener('availabilitychanged', event => onAvailabilityChanged(event.value));
}

export async function getDevices({ ble }) {
  const devices = await ble.getDevices();
  return devices;
}

export async function requestDevice({ ble, filters, optionalServices }) {
  const device = await ble.requestDevice({ filters, optionalServices });
  return device;
}

export function addDisconnectedListener({ device, onDisconnected }) {
  device.addEventListener('gattserverdisconnected', onDisconnected, { once: true });
}

export async function connect({ device }) {
  if (device.gatt.connected) {
    return;
  }
  const server = await device.gatt.connect();
  addDisconnectedListener({ device, onDisconnected: reset });
  return server;
}

export async function disconnect({ device }) {
  if (!device.gatt.connected) {
    return;
  }
  device.gatt.disconnect();
}

export async function getCharacteristic({ device, serviceUuid, characteristicUuid }) {
  const service = await device.gatt.getPrimaryService(serviceUuid);
  const characteristic = await service.getCharacteristic(characteristicUuid);
  return characteristic;
}

export const read = wrap(async ({ device, serviceUuid, characteristicUuid }) => {
  const characteristic = await getCharacteristic({ device, serviceUuid, characteristicUuid });
  return characteristic.readValue();
});

export const write = wrap(async ({ device, serviceUuid, characteristicUuid, value, withoutResponse = false }) => {
  const characteristic = await getCharacteristic({ device, serviceUuid, characteristicUuid });
  if (withoutResponse) {
    await characteristic.writeValueWithoutResponse(value);
  } else {
    await characteristic.writeValueWithResponse(value);
  }
});

export const startNotifications = wrap(async ({ device, serviceUuid, characteristicUuid }) => {
  const characteristic = await getCharacteristic({ device, serviceUuid, characteristicUuid });
  return await characteristic.startNotifications();
});

export const stopNotifications = wrap(async ({ device, serviceUuid, characteristicUuid }) => {
  const characteristic = await getCharacteristic({ device, serviceUuid, characteristicUuid });
  return await characteristic.stopNotifications();
});

export async function addValueChangedListener({ device, serviceUuid, characteristicUuid, onValueChanged }) {
  const characteristic = await getCharacteristic({ device, serviceUuid, characteristicUuid });
  const handleCharacteristicValueChanged = event => onValueChanged(event.target.value);
  const cancel = () =>
    characteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
  characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
  addDisconnectedListener({ device, onDisconnected: cancel });
  return { cancel }
}

export const buildOnListValueChanged = ({ onError, onFinish, onCount, onValue }) => data => {
  const dataView = new DataView(data.buffer);
  const type = dataView.getUint8(0);
  if (type === 0x00) {
    onFinish();
  } else if (type === 0x01) {
    const count = dataView.getUint32(1, true);
    onCount(count);
  } else if (type === 0x02) {
    onValue(dataView.buffer.slice(1));
  } else if (type === 0xff) {
    let errorType = -1;
    if (dataView.byteLength > 1) {
      errorType = dataView.getUint8(1);
    }
    onError(errorType);
  } else {
    console.log(`Unknown: list value type=${type}`);
  }
};

export const readList = async ({
  device,
  serviceUuid,
  characteristicUuid,
  value,
  onCount = _ => { },
  onPayload = _ => { },
  onStats = _ => { },
}) => {
  const start = Date.now();
  let writeAvg = 0;
  let readDiff = 0;
  let readCount = 0;
  let processDiff = 0;
  let processCount = 0;

  const callStats = () => {
    const diff = Date.now() - start;
    const readAvg = readDiff / readCount;
    const processAvg = processDiff / processCount;
    onStats({ diff, writeAvg, readAvg, processAvg });
  };
  const callStatsInterval = setInterval(callStats, 2000);

  try {
    const writeStart = Date.now();
    await write({ device, serviceUuid, characteristicUuid, value });
    writeAvg = Date.now() - writeStart;

    while (true) {
      const readStart = Date.now();
      const res = await read({ device, serviceUuid, characteristicUuid });
      readDiff += Date.now() - readStart;
      readCount++;

      const processStart = Date.now();
      const resView = new DataView(res.buffer);
      const type = resView.getUint8(0);
      if (type === 0xff) {
        const errorType = resView.getUint8(1);
        throw new Error(`error ${errorType} while reading list`);
      } else if (type === 0x00) {
        break;
      } else if (type === 0x01) {
        const count = resView.getUint32(1, true);
        onCount(count);
      } else if (type === 0x02) {
        const payload = resView.buffer.slice(1);
        onPayload(payload);
      }
      processDiff += Date.now() - processStart;
      processCount++;
    }
  } finally {
    clearInterval(callStatsInterval);
    callStats();
  }
};
