import React from 'react'
import {
  connectAuthEmulator,
  initializeAuth,
} from 'firebase/auth';
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
} from 'firebase/firestore';
import {
  AuthProvider,
  FirestoreProvider,
  FirebaseAppProvider,
  useInitFirestore,
  useInitAuth,
} from 'reactfire';
import Splash from './Splash';

const firebaseConfig = {
  apiKey: 'AIzaSyAI7xFKHaBh1TQzqrYeGiINmelstX8TL-0',
  authDomain: 'marigold-a2e1d.firebaseapp.com',
  projectId: 'marigold-a2e1d',
  storageBucket: 'marigold-a2e1d.appspot.com',
  messagingSenderId: '226696879962',
  appId: '1:226696879962:web:6c02843c8399558cbb9d63',
  measurementId: 'G-22DF45NEMJ',
};

function FirebaseComponents({ children }) {
  const { status: authStatus, data: auth } = useInitAuth(async (app) => {
    const auth = initializeAuth(app);
    if (process.env.NODE_ENV !== 'production') {
      connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    }
    return auth;
  });

  const { status: firestoreStatus, data: firestore } = useInitFirestore(async (app) => {
    const firestore = initializeFirestore(app, { cacheSizeBytes: CACHE_SIZE_UNLIMITED });
    if (process.env.NODE_ENV !== 'production') {
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
    await enableIndexedDbPersistence(firestore);
    return firestore;
  });


  if (authStatus === 'loading' || firestoreStatus === 'loading') {
    return <Splash />;
  }

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        {children}
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default function MarigoldFirebase({ children }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>
        {children}
      </FirebaseComponents>
    </FirebaseAppProvider>
  )
}
