import moment from 'moment';

export const isNonEmptyString = str => typeof str === 'string' && str.length > 0;
export const isNonZeroLength = arr => Array.isArray(arr) && arr.length > 0;
export const isNonNullObject = obj => typeof obj === 'object' && obj !== null;

export const getRidName = (rid) => {
  switch (rid) {
    // Globals for general info
    case 8: return 'Info Device Type';
    case 9: return 'Info Serial Number';
    case 10: return 'Info Firmware Version';
    case 11: return 'Info Bootloader Version';

    // Globals for Auto Measurements
    case 15: return 'Measurement Probe Serial Number';
    case 16: return 'Measurement Oldest Timestamp';
    case 17: return 'Measurement Number Stored';
    case 18: return 'Measurement Bitwidth';
    case 19: return 'Measurement Bitwidth Index';
    case 20: return 'Measurement Interval Seconds';
    case 21: return 'Measurement Oldest FL ID';
    case 22: return 'Measurement Oldest Memory Add';

    // Globals for system status
    case 24: return 'SYS Battery Level';
    case 25: return 'SYS RTC Time';
    case 26: return 'SYS Reset Timestamp';
    case 29: return 'SYS Flags';

    // Globals for Firmware partition
    case 32: return 'Firmware Partition Size';
    case 33: return 'Firmware Partition NVMEM ID';
    case 34: return 'Firmware Partition Address';
    case 35: return 'Firmware Partition Update';

    // Globals for NVMEM access
    case 40: return 'NVMEM ID List';
    case 41: return 'NVMEM ID';
    case 42: return 'NVMEM Key';
    case 43: return 'NVMEM Size';
    case 47: return 'NVMEM Memory Add';
    case 48: return 'NVMEM Data';

    default: return `RID ${rid}`;
  }
};

export const reverseHexString = str => {
  if (!isNonEmptyString(str)) {
    return '';
  }
  if (str.length % 2 !== 0) {
    console.log(`str is not even length: '${str}'`);
  }
  const bytes = [];
  for (let i = 0; i < str.length / 2; i++) {
    const index = i * 2;
    bytes.splice(0, 0, str.slice(index, index + 2));
  }
  return bytes.join('');
};

export const numSettingParseFn =
  str => Number.parseInt(reverseHexString(str), 16);

export const secondsSettingParseFn =
  str => moment.unix(numSettingParseFn(str)).format('YYYY-MM-DD HH:mm:ss');

export const getParseFn = rid => {
  switch (rid) {
    case 16:
    case 25:
    case 26:
      return secondsSettingParseFn;
    default:
      return numSettingParseFn;
  }
};

export const byteToHex = num => num.toString(16).padStart(2, '0');

export const bytesToHex = buffer => {
  return [...new Uint8Array(buffer)].map(byteToHex).join('');
}

export const clampDebounce = (fn, short, long) => {
  let shortTimeout;
  let longTimeout = null;
  return (...params) => {
    clearTimeout(shortTimeout);
    shortTimeout = setTimeout(() => {
      clearTimeout(longTimeout);
      longTimeout = null;
      fn(...params);
    }, short);
    if (longTimeout === null) {
      longTimeout = setTimeout(() => {
        clearTimeout(shortTimeout);
        longTimeout = null;
        fn(...params);
      }, long);
    }
  };
};

export const debounce = (fn, wait) => {
  let timeout;
  return (...params) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...params), wait);
  };
};

export const getRandomInt = ({ min = 0, max = 0 } = {}) =>
  Math.floor(Math.random() * (max - min)) + min;
