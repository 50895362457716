import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { readDownsampledMeasurements } from '../lib/ble/measurement';
import {
  createStudy as storageCreateStudy,
  deleteStudy as storageDeleteStudy,
  getStudyMeasurementsByTimestamp,
  loadDownSampledMeasurements,
  loadStudies,
  loadStudy,
} from '../lib/storage/studies';
import { selectBle } from './ble';
import { handlePending, handleFulfilled, handleError } from './util';

export const createStudy = createAsyncThunk(
  'studies/create',
  async (request, api) => {
    const { user, study } = request;
    const data = await storageCreateStudy({ user, study });
    return data;
  },
);

export const deleteStudy = createAsyncThunk(
  'studies/delete',
  async (request, api) => {
    const { user, study } = request;
    const id = await storageDeleteStudy({ user, study });
    return id;
  },
);

export const fetchStudies = createAsyncThunk(
  'studies/fetchAll',
  async (request, api) => {
    const { user } = request;
    const data = await loadStudies({ user });
    return data;
  },
);

export const fetchStudy = createAsyncThunk(
  'studies/fetch',
  async (request, api) => {
    const { user, id } = request;
    const data = await loadStudy({ user, id });
    return data;
  },
);

export const fetchDownSampledMeasurements = createAsyncThunk(
  'studies/fetchDownSampledMeasurements',
  async (request, api) => {
    const { user, study, start, end } = request;
    const { connected, peripheral } = selectBle(api.getState());

    let samples;
    if (connected) {
      const serialNumbers = Object.keys(study.serialNumbers);
      samples = await serialNumbers.reduce(async (prev, serialNumber) => {
        const samples = await prev;
        samples[serialNumber] = await readDownsampledMeasurements({ peripheral, serialNumber, start, end });
        return samples;
      }, Promise.resolve({}))

    } else {
      samples = await loadDownSampledMeasurements({ user, study, start, end });
    }

    Object.keys(samples).forEach(serialNumber => {
      const points = samples[serialNumber];
      points.forEach((point, index, points) => {
        const prevIsMissing = (index > 0 && points[index - 1].count === 0);
        const nextIsMissing = (index < points.length - 1 && points[index + 1].count === 0);
        point.lonely = (prevIsMissing && nextIsMissing);
      });
    });
    return samples;
  },
);

const timestampMeasurementToCsvRow = serialNumbers => ({ timestamp, measurements }) => {
  const row = serialNumbers.reduce(
    (row, serialNumber) => {
      row += ',';
      if (measurements.has(serialNumber)) {
        const measurement = measurements.get(serialNumber);
        row += `${measurement.temperature}`;
      }
      return row;
    }, `${timestamp},${moment.unix(timestamp).format('YYYY-MM-DD hh:mm:ss')}`);
  return row + '\n';
};

export const exportStudyMeasurements = createAsyncThunk(
  'studies/exportStudyMeasurements',
  async (request, api) => {
    const { user, study } = request;
    const serialNumbers = Object.keys(study.serialNumbers).sort();

    api.dispatch(setMessage('Loading measurements'));
    const measurementsByTimestamp = await getStudyMeasurementsByTimestamp({ user, study });
    const timestampMeasurements = Array.from(measurementsByTimestamp.keys())
      .sort((a, b) => a - b)
      .map(timestamp => ({
        timestamp,
        measurements: measurementsByTimestamp.get(timestamp),
      }));

    api.dispatch(setMessage('Creating CSV'));
    const header = serialNumbers.reduce(
      (header, serialNumber) =>
        `${header},Temperature ${serialNumber}`, 'Unix Timestamp, Date/Time')
      + '\n';
    const csv = timestampMeasurements
      .map(timestampMeasurementToCsvRow(serialNumbers))
      .reduce((csv, row) => csv + row, header);

    api.dispatch(setMessage('Downloading CSV'));
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    link.target = '_blank';
    link.download = `Study ${study.name} - ${study.startDate}.csv`;
    link.click();

    api.dispatch(setMessage('Export finished'));
  },
);

const studiesAdapter = createEntityAdapter({
  selectId: study => study._id,
});

const studiesSlice = createSlice({
  name: 'studies',
  initialState: studiesAdapter.getInitialState({
    loading: 'idle',
    error: null,
    downSamples: {},
    message: '',
  }),
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: {
    [createStudy.pending]: handlePending(),
    [createStudy.fulfilled]: handleFulfilled((state, action) =>
      studiesAdapter.upsertOne(state, action.payload)),
    [createStudy.rejected]: handleError(),
    [deleteStudy.pending]: handlePending(),
    [deleteStudy.fulfilled]: handleFulfilled((state, action) =>
      studiesAdapter.removeOne(state, action.payload)),
    [deleteStudy.rejected]: handleError(),
    [fetchStudies.pending]: handlePending(),
    [fetchStudies.fulfilled]: handleFulfilled((state, action) =>
      studiesAdapter.upsertMany(state, action.payload.studies.map(row => row.doc))),
    [fetchStudies.rejected]: handleError(),
    [fetchStudy.pending]: handlePending(),
    [fetchStudy.fulfilled]: handleFulfilled((state, action) =>
      studiesAdapter.upsertOne(state, action.payload)),
    [fetchStudy.rejected]: handleError(),
    [exportStudyMeasurements.pending]: handlePending(),
    [exportStudyMeasurements.fulfilled]: handleFulfilled(),
    [exportStudyMeasurements.rejected]: handleError(),
    [fetchDownSampledMeasurements.pending]: (state, action) => {
      const key = action.meta.arg.study._id;
      state.downSamples[key] = { status: 'loading', data: null, error: null };
    },
    [fetchDownSampledMeasurements.fulfilled]: (state, action) => {
      const key = action.meta.arg.study._id;
      state.downSamples[key] = { status: 'success', data: action.payload, error: null };
    },
    [fetchDownSampledMeasurements.rejected]: (state, action) => {
      const key = action.meta.arg.study._id;
      state.downSamples[key] = { status: 'error', data: null, error: action.error.message };
    },
  },
});

export const selectDownSamples = (state, study) => {
  if (!study) {
    return { init: false, status: 'pending', data: null, error: null };
  }
  const key = study._id;
  const downSamples = state.studies.downSamples[key];
  if (!downSamples) {
    return { init: false, status: 'pending', data: null, error: null };
  }
  return { ...downSamples, init: true };
};

export const {
  selectById: selectStudyById,
  selectAll: selectAllStudies,
} = studiesAdapter.getSelectors(state => state.studies);
export const selectLoading = state => 'pending' === state.studies.loading;
export const selectError = state => state.studies.error;
export const selectMessage = state => state.studies.message;

const { actions, reducer } = studiesSlice;

const { setMessage } = actions;
export default reducer;
