import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import Splash from './Splash';

export default function RequireAuth({ children }) {
  const { initialized, user } = useAuth();
  const location = useLocation();

  if (!initialized || user === undefined) {
    return <Splash />;
  }
  if (!user) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};
