import { getUserDb } from './db';

const options = {
  name: 'devices',
  indexes: [],
  hook: async (db) => {
    const result = await db.allDocs({
      keys: [
        '_design/devices',
      ],
    });
    if (result.rows[0].error === 'not_found' || result.rows[0].value.deleted) {
      await db.put({
        _id: '_design/devices',
        filters: {
          deviceFilter: function (doc) {
            return doc._id.startsWith('device_');
          }.toString(),
        },
      });
    }
  },
};

const makeKey = serialNumber => `device_${serialNumber}`;

export async function getDb(user) {
  return getUserDb({ user, options });
}

export const listDevices = async ({ user }) => {
  const db = await getDb(user);
  const { rows } = await db.allDocs({
    include_docs: true,
    startkey: makeKey(''),
    endkey: makeKey('\uffff'),
  });
  return rows.map(row => row.doc);
};

export const getById = async ({ user, serialNumber }) => {
  const db = await getDb(user);
  return await db.get(makeKey(serialNumber));
};

export async function find(user, request) {
  const db = await getDb(user);
  const result = await db.find(request);
  if (result.warning) {
    console.warn(result.warning, request);
  }
  return result;
}

export const upsertDeviceList = async ({ user, devices }) => {
  const db = await getDb(user);

  const keys = devices.map(device => makeKey(device.serialNumber));
  const fetchResult = await db.allDocs({ include_docs: true, keys });

  const newDevices = [];
  fetchResult.rows.forEach((row, index) => {
    if (row.error === 'not_found' || row.value.deleted) {
      const device = devices[index];
      newDevices.push({
        _id: makeKey(device.serialNumber),
        ...device,
      });
    } else {
      const device = row.doc;
      const deviceUpdate = devices[index];
      newDevices.push({
        ...device,
        ...deviceUpdate,
      });
    }
  });

  await db.bulkDocs(newDevices);
};

export const updateDeviceSettings = async ({ user, serialNumber, settings = [] }) => {
  const db = await getDb(user);

  const key = makeKey(serialNumber);
  const device = await db.get(key);

  device.settings = settings;
  await db.put(device);
};
