import React, { lazy, Suspense } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import 'fontsource-roboto';
import './App.css';

import Splash from './components/Splash';
import { useAuth } from './hooks/use-auth';
import { MarigoldProviders } from './components/MarigoldProviders';
import RequireAuth from './components/RequireAuth';

const AboutPage = lazy(() => import('./components/about/About'));
const BaseStationsPage = lazy(() => import('./components/base-station/BaseStationsPage'));
const DataSourceDetails = lazy(() => import('./components/data-sources/DataSourceDetails'));
const DataSourceList = lazy(() => import('./components/data-sources/DataSourceList'));
const DataSourcesPage = lazy(() => import('./components/data-sources/DataSourcesPage'));
const PasswordResetPage = lazy(() => import('./pages/PasswordResetPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const SignInPage = lazy(() => import('./pages/SignInPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const StudiesPage = lazy(() => import('./components/studies/StudiesPage'));
const StudyCreate = lazy(() => import('./components/studies/StudyCreate'));
const StudyDetails = lazy(() => import('./components/studies/StudyDetails'));
const StudyList = lazy(() => import('./components/studies/StudyList'));

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
}

function AppRouter() {
  const { initialized } = useAuth();
  if (!initialized) {
    return <Splash />;
  }
  return (
    <Suspense fallback={<Splash />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RequireAuth><BaseStationsPage /></RequireAuth>} />
          <Route path="base-station" element={<RequireAuth><BaseStationsPage /></RequireAuth>} />
          <Route path="data-sources" element={<DataSourcesPage />}>
            <Route index element={<RequireAuth><DataSourceList /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth><DataSourceDetails /></RequireAuth>} />
          </Route>
          <Route path="studies" element={<StudiesPage />}>
            <Route index element={<RequireAuth><StudyList /></RequireAuth>} />
            <Route path="create" element={<RequireAuth><StudyCreate /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth><StudyDetails /></RequireAuth>} />
          </Route>
          <Route path="settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
          <Route path="about" element={<RequireAuth><AboutPage /></RequireAuth>} />

          <Route path="signup" element={<SignUpPage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="password-reset" element={<PasswordResetPage />} />

          <Route path="*" element={<Navigate replace to="/base-station" />} />
        </Route>
      </Routes>
    </Suspense >
  );
}

function App() {
  return (
    <MarigoldProviders>
      <CssBaseline />
      <AppRouter />
    </MarigoldProviders>
  );
}

export default App;
