import { getUserDb } from './db';

const options = {
  name: 'measurements',
  hook: async (db) => {
    const result = await db.allDocs({
      keys: [
        '_design/measurements',
      ],
    });
    if (result.rows[0].error === 'not_found' || result.rows[0].value.deleted) {
      await db.put({
        _id: '_design/measurements',
        filters: {
          deviceFilter: function (doc) {
            return doc._id.startsWith('measurement_');
          }.toString(),
        },
      });
    }
  },
};

export async function getDb(user) {
  return getUserDb({ user, options });
}

const makeKey = (serialNumber, timestamp) => `measurement_${serialNumber}_${timestamp}`;

export const getMeasurementsForSerialNumber = async ({ user, serialNumber, start, end }) => {
  const db = await getDb(user);
  const { rows } = await db.allDocs({
    include_docs: true,
    startkey: makeKey(serialNumber, start),
    endkey: makeKey(serialNumber, end),
    inclusive_end: true,
  });
  const measurements = rows.map(({ doc }) => doc);
  return measurements;
}

const getInfosByBucketForMeasurements = ({ measurements, start, delta }) => {
  const infosByBucket = new Map();
  measurements.forEach(({ timestamp, temperature, tscore }) => {
    const bucket = Math.floor((timestamp - start) / delta);
    if (!infosByBucket.has(bucket)) {
      infosByBucket.set(bucket, {
        count: 0,
        temperature: 0,
        tscore: 0,
      });
    }
    const info = infosByBucket.get(bucket);
    info.count++;
    info.temperature += temperature;
    info.tscore += tscore;
  });
  return infosByBucket;
};

const getDataForInfosByBucket = ({ infosByBucket, start, steps, delta }) => {
  const infos = [];
  for (let bucket = 0; bucket < steps; bucket++) {
    const info = {
      timestamp: start + delta * bucket,
      count: 0,
      temperature: null,
      tscore: 0,
    };
    if (infosByBucket.has(bucket)) {
      const bucketInfo = infosByBucket.get(bucket);
      info.count = bucketInfo.count;
      info.temperature = Math.round(bucketInfo.temperature * 100 / bucketInfo.count) / 100;
      info.tscore = Math.round(bucketInfo.tscore * 100 / bucketInfo.count) / 100;
    }
    infos.push(info);
  }
  return infos;
};

export const getDownSampledMeasurements = async ({ user, serialNumber, start, end, steps = 128, minSteps = 4 }) => {
  const measurements = await getMeasurementsForSerialNumber({ user, serialNumber, start, end });
  if (steps > measurements.length) {
    steps = measurements.length;
  }
  if (steps < minSteps) {
    steps = minSteps;
  }
  const duration = end - start;
  const delta = duration / steps;
  const infosByBucket = getInfosByBucketForMeasurements({ measurements, delta, start });
  return getDataForInfosByBucket({ infosByBucket, start, steps, delta });
};

export async function find(user, request) {
  const db = await getDb(user);
  // const explaination = await db.explain(request);
  // console.log(explaination);
  const result = await db.find(request);
  if (result.warning) {
    console.warn(result.warning, request);
  }
  return result;
}

export async function readDeviceMeasurementTimestampMaximum({ user, serialNumber }) {
  const db = await getDb(user);

  const result = await db.allDocs({
    include_docs: true,
    startkey: makeKey(serialNumber, '\uffff'),
    endkey: makeKey(serialNumber, ''),
    limit: 1,
    descending: true,
  });
  if (result.rows.length > 0) {
    return result.rows[0].doc.timestamp;
  }
  return 0;
}

export async function readDeviceMeasurementTimestampMinimum({ user, serialNumber }) {
  const db = await getDb(user);

  const result = await db.allDocs({
    include_docs: true,
    startkey: makeKey(serialNumber, ''),
    endkey: makeKey(serialNumber, '\uffff'),
    limit: 1,
  });
  if (result.rows.length > 0) {
    return result.rows[0].doc.timestamp;
  }
  return 0;
}

export async function readDeviceMeasurementCount({
  user,
  serialNumber,
  start = 0,
  end = Number.MAX_SAFE_INTEGER,
}) {
  const db = await getDb(user);

  const { rows, total_rows: total } = await db.allDocs({
    startkey: makeKey(serialNumber, start),
    endkey: makeKey(serialNumber, end),
    inclusive_end: true,
  });
  const count = rows.length;
  return { count, total };
}

export const writeDeviceMeasurementList = async ({ user, serialNumber, measurements = [] }) => {
  const db = await getDb(user);

  const keys = measurements.map(m => makeKey(serialNumber, m.timestamp));
  const fetchResult = await db.allDocs({ include_docs: true, keys });

  const rowsToCreate = [];
  fetchResult.rows.forEach((row, index) => {
    if (row.error === 'not_found') {
      const { timestamp, tscore, temperature } = measurements[index];
      rowsToCreate.push({
        _id: makeKey(serialNumber, timestamp),
        serialNumber,
        timestamp,
        tscore,
        temperature,
      });
    }
  });

  if (rowsToCreate.length > 0) {
    await db.bulkDocs(rowsToCreate);
  }
};
