import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { readDeviceList } from "../lib/ble/device";
import { getById, listDevices, upsertDeviceList } from "../lib/storage/devices";
import { handleError, handleFulfilled, handlePending, selectError, selectLoading } from "./util";

const dataSourcesAdapter = createEntityAdapter({
  selectId: ds => ds.serialNumber,
});

export const readDeviceListFromBase = createAsyncThunk(
  "dataSources/readDeviceListFromBase",
  async (request, api) => {
    const { user, device } = request;
    const devices = await readDeviceList({ device });
    await api.dispatch(writeDeviceListToStorage({ user, devices }));
    return devices;
  },
);

export const readDeviceListFromStorage = createAsyncThunk(
  "dataSources/readDeviceListFromStorage",
  async (request, api) => {
    const { user } = request;
    const devices = await listDevices({ user });
    return devices;
  },
);

export const readDeviceFromStorage = createAsyncThunk(
  "dataSources/readDeviceFromStorage",
  async (request, api) => {
    const { user, serialNumber } = request;
    const data = await getById({ user, serialNumber });
    return data;
  },
);

export const writeDeviceListToStorage = createAsyncThunk(
  "dataSources/writeDeviceListToStorage",
  async (request, api) => {
    const { user, devices } = request;
    await upsertDeviceList({ user, devices });
  },
);

const dataSourceSlice = createSlice({
  name: "dataSources",
  initialState: dataSourcesAdapter.getInitialState({
    loading: 0,
    error: null,
  }),
  reducers: {
    upsertDataSource: (state, action) => {
      dataSourcesAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: {
    [readDeviceListFromBase.pending]: handlePending(),
    [readDeviceListFromBase.fulfilled]: handleFulfilled((state, action) => {
      const devices = action.payload;
      dataSourcesAdapter.upsertMany(state, devices);
    }),
    [readDeviceListFromBase.rejected]: handleError(),

    [readDeviceListFromStorage.pending]: handlePending(),
    [readDeviceListFromStorage.fulfilled]: handleFulfilled((state, action) =>
      dataSourcesAdapter.upsertMany(state, action.payload)),
    [readDeviceListFromStorage.rejected]: handleError(),

    [readDeviceFromStorage.pending]: handlePending(),
    [readDeviceFromStorage.fulfilled]: handleFulfilled((state, action) =>
      dataSourcesAdapter.upsertOne(state, action.payload)),
    [readDeviceFromStorage.rejected]: handleError(),

    [writeDeviceListToStorage.pending]: handlePending(),
    [writeDeviceListToStorage.fulfilled]: handleFulfilled((state, action) => {
      const { devices } = action.meta.arg;
      dataSourcesAdapter.upsertMany(state, devices);
    }),
    [writeDeviceListToStorage.rejected]: handleError(),
  },
});

const selectDataSourcesState = state => state.dataSources;

export const {
  selectById,
  selectAll,
} = dataSourcesAdapter.getSelectors(selectDataSourcesState);

export const selectDataSourcesLoading = selectLoading(selectDataSourcesState);
export const selectDataSourcesError = selectError(selectDataSourcesState);
export const selectDataSource = serialNumber => state => selectById(state, serialNumber);

export const { upsertDataSource } = dataSourceSlice.actions;

const { reducer } = dataSourceSlice;
export default reducer;
