import { getUserDb } from './db';

const options = {
  name: 'device-settings',
  indexes: [],
  hook: async (db) => {
    const result = await db.allDocs({
      keys: [
        '_design/device-settings',
      ],
    });
    if (result.rows[0].error === 'not_found' || result.rows[0].value.deleted) {
      await db.put({
        _id: '_design/device-settings',
        filters: {
          deviceFilter: function (doc) {
            return doc._id.startsWith('device_setting_');
          }.toString(),
        },
      });
    }
  },
};

const makeKey = (serialNumber, rid) => `device_setting_${serialNumber}_${rid}`;
const getDb = async user => getUserDb({ user, options });

export const listDeviceSettings = async ({ user, serialNumber }) => {
  const db = await getDb(user);
  const { rows } = await db.allDocs({
    include_docs: true,
    startkey: makeKey(serialNumber, ''),
    endkey: makeKey(serialNumber, '\uffff'),
  });
  const deviceSettings = rows.map(row => row.doc);
  return deviceSettings;
};

export const upsertDeviceSettings = async ({ user, deviceSettings }) => {
  const db = await getDb(user);

  const keys = deviceSettings.map(deviceSetting => makeKey(deviceSetting.serialNumber, deviceSetting.rid));
  const { rows } = await db.allDocs({ include_docs: true, keys });

  const newDeviceSettings = rows.map((row, index) => {
    if (row.error === 'not_found' || row.value.deleted) {
      const deviceSetting = deviceSettings[index];
      return {
        _id: makeKey(deviceSetting.serialNumber, deviceSetting.rid),
        ...deviceSetting,
      };
    } else {
      const deviceSetting = row.doc;
      const deviceSettingUpdate = deviceSettings[index];
      return {
        ...deviceSetting,
        ...deviceSettingUpdate,
      };
    }
  });

  await db.bulkDocs(newDeviceSettings);
};
