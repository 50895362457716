export const BASE_STATION_SRVC_UUID = 0x3f00;
export const BASE_STATION_INFORMATION_CHAR_UUID = 0x3f01;
export const BASE_STATION_SETTING_LIST_CHAR_UUID = 0x3f02;
export const BASE_STATION_SETTING_UPDATE_CHAR_UUID = 0x3f03;

export const DEVICE_SRVC_UUID = 0x3f10;
export const DEVICE_LIST_CHAR_UUID = 0x3f11;

export const SETTING_SRVC_UUID = 0x3f20;
export const SETTING_LIST_CHAR_UUID = 0x3f21;
export const SETTING_UPDATE_CHAR_UUID = 0x3f22;

export const MEASUREMENT_SRVC_UUID = 0x3f30;
export const MEASUREMENT_COUNT_CHAR_UUID = 0x3f31;
export const MEASUREMENT_LIST_CHAR_UUID = 0x3f32;
export const MEASUREMENT_DOWNSAMPLED_CHAR_UUID = 0x3f33;

export const WIFI_SCANNER_SRVC_UUID = 0x3f40;
export const WIFI_SCANNER_STATE_CHAR_UUID = 0x3f41;

export const WIFI_CONFIG_SRVC_UUID = 0x3f50;
export const WIFI_CONFIG_STATE_CHAR_UUID = 0x3f51;
