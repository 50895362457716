import React, { useCallback, useContext, createContext } from 'react';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { useFirebaseApp, useUser } from 'reactfire';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const auth = getAuth(useFirebaseApp());
  const { status, data: user } = useUser({ initialData: null });
  const initialized = (status !== 'loading');
  return {
    initialized,
    user,
    signInAnonymously: useCallback(() =>
      signInAnonymously(auth),
      [auth]),
    signin: useCallback((email, password) =>
      signInWithEmailAndPassword(auth, email, password),
      [auth]),
    signup: useCallback((email, password) =>
      createUserWithEmailAndPassword(auth, email, password),
      [auth]),
    signout: useCallback(() => signOut(auth),
      [auth]),
    sendPasswordResetEmail: useCallback((email) =>
      sendPasswordResetEmail(auth, email)
        .then(() => true),
      [auth]),
    confirmPasswordReset: useCallback((code, password) =>
      confirmPasswordReset(auth, code, password)
        .then(() => true),
      [auth]),
    checkEmail: useCallback((email) =>
      fetchSignInMethodsForEmail(auth, email),
      [auth]),
  };
}