export const isLonely = (index, points) => {
  const isNotEmpty = points[index].count > 0;
  const prevIsEmpty = (index === 0 || points[index - 1].count === 0);
  const nextIsEmpty = (index === points.length - 1 || points[index + 1].count === 0);
  return isNotEmpty && prevIsEmpty && nextIsEmpty;
};

export const handlePending = (consumer = _ => { }) => (state, action) => {
  state.loading += 1;
  state.error = null;
  consumer(state, action);
};

export const handleFulfilled = (consumer = _ => { }) => (state, action) => {
  if (state.loading > 0) {
    state.loading -= 1;
  }
  consumer(state, action);
};

export const handleError = (consumer = _ => { }) => (state, action) => {
  if (state.loading > 0) {
    state.loading -= 1;
  }
  state.error = action.error.message;
  consumer(state, action);
};

export const selectLoading = selectStateFn => state => selectStateFn(state).loading > 0;
export const selectError = selectStateFn => state => selectStateFn(state).error;
