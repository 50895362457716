import { configureStore } from '@reduxjs/toolkit';
import bleReducer from '../features/ble';
import deviceSettingsReducer from '../features/device-settings';
import dataSourcesReducer from '../features/data-sources';
import measurementsReducer from '../features/measurements';
import studiesReducer from '../features/studies';

export const store = configureStore({
  reducer: {
    ble: bleReducer,
    deviceSettings: deviceSettingsReducer,
    dataSources: dataSourcesReducer,
    measurements: measurementsReducer,
    studies: studiesReducer,
  },
});
