import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import { StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '../app/store';
import { ProvideAuth } from '../hooks/use-auth';
import MarigoldFirebase from './MarigoldFirebase';

const theme = createTheme({
  palette: {
    primary: {
      main: '#474f6b',
    },
    secondary: {
      main: '#ff9800',
    },
  }
});

export const MarigoldProviders = ({ children }) => {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <MarigoldFirebase>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ProvideAuth>
                  {children}
                </ProvideAuth>
              </LocalizationProvider>
            </MarigoldFirebase>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};