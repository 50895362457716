import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { readDownsampledMeasurements } from '../lib/ble/measurement';
import { getDownSampledMeasurements } from '../lib/storage/measurements';
import { selectBleConnected, selectBaseDevice } from './ble';

export const isLonely = (index, points) => {
  const isNotEmpty = points[index].count > 0;
  const prevIsEmpty = (index === 0 || points[index - 1].count === 0);
  const nextIsEmpty = (index === points.length - 1 || points[index + 1].count === 0);
  return isNotEmpty && prevIsEmpty && nextIsEmpty;
};

export const computeDeviceDownSamples = createAsyncThunk(
  'measurements/computeDeviceDownSamples',
  async (request, api) => {
    const { user, serialNumber, start, end } = request;
    const connected = selectBleConnected(api.getState());

    let points;
    if (connected) {
      const device = selectBaseDevice(api.getState());
      points = await readDownsampledMeasurements({
        device,
        serialNumber,
        start,
        end,
        requestId: api.requestId,
      });
    } else {
      points = await getDownSampledMeasurements({ user, serialNumber, start, end });
    }
    points.forEach((point, index, points) => {
      point.lonely = isLonely(index, points);
    });
    return points;
  },
);

const measurementsSlice = createSlice({
  name: 'measurements',
  initialState: {
    status: {},
    downSamples: {},
  },
  reducers: {},
  extraReducers: {
    [computeDeviceDownSamples.pending]: (state, action) => {
      const { serialNumber } = action.meta.arg;
      state.status[serialNumber] = 'pending';
      state.downSamples[serialNumber] = { data: null, error: null };
    },
    [computeDeviceDownSamples.fulfilled]: (state, action) => {
      const { serialNumber } = action.meta.arg;
      state.status[serialNumber] = 'success';
      state.downSamples[serialNumber] = { data: action.payload, error: null };
    },
    [computeDeviceDownSamples.rejected]: (state, action) => {
      console.log(action.error);
      const { serialNumber } = action.meta.arg;
      state.status[serialNumber] = 'error';
      state.downSamples[serialNumber] = { data: null, error: action.error.message };
    },
  },
});

export const selectDownSamples = serialNumber => state => {
  if (!serialNumber || !state.measurements.status[serialNumber]) {
    return { init: false, status: 'pending', data: null, error: null };
  }
  const status = state.measurements.status[serialNumber];
  const { data, error } = state.measurements.downSamples[serialNumber];
  return { init: true, status, data, error };
};

const { reducer } = measurementsSlice;

export default reducer;
